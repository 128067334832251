import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";


export const getStatus = enumStatus => {
    switch (enumStatus) {
        case 'REQUESTED':
            return 'Attente';
            break;

        case 'APPROVED':
            return 'Validée';
            break;

        case 'REFUSED':
            return 'Refusée';
            break;
        case 'ACCEPTED':
            return 'Acceptée';
            break;
        case 'NOTACCEPTED':
            return 'Non acceptée';
            break;
        case 'OTHER':
            return 'Sans objet';
            break;
        default :
            return ""
    }
};


export const LeaveValidateColumn = [
    {
        name: <IntlMessages
            id="leave.attribute.employee"
        />,
        selector: 'employee',
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.employee.firstName} {row.employee.lastName}</h2>
    },
    {
        name: <IntlMessages
            id="leave.datatable.periode"
        />,
        selector: 'name',
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.start} - {row.end}</h2>
    },
    {
        name: <IntlMessages
            id="leave.datatable.total"
        />,
        selector: 'total',
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.total} J</h2>
    },
    {
        name: <IntlMessages
            id="leave.attribute.statusClient"
        />,
        selector: 'statusClient',
        sortable: false,
        filtrable: true,
        cell: row => <div>
            {getStatus(row.statusClient)}
        </div>
    },
    {
        name: <IntlMessages
            id="leave.attribute.statusRH"
        />,
        selector: 'statusRH',
        sortable: false,
        filtrable: true,
        cell: row => <div>
            {getStatus(row.statusRH)}
        </div>
    }
];