const NOMENCLATURE_HOST = "http://localhost:8090";
const LEAVE_HOST = "http://localhost:8081";

export const NOMENCLATURE_NAME = "NOMENCLATURE";
export const LEAVE_NAME = "LEAVE";
export const EMPLOYEE_NAME = "EMPLOYE";

export const getHostByAppName = appName => {
    if (process.env.NODE_ENV === 'local') {
        switch (appName) {
            case NOMENCLATURE_NAME:
                return NOMENCLATURE_HOST;
                break;

            case LEAVE_NAME:
                return LEAVE_HOST;
                break;

            default :
                return ""
        }
    } else {
        switch (appName) {
            case NOMENCLATURE_NAME:
                return `${process.env.REACT_APP_API_URL}/nomenclature-service`;
                break;
            case EMPLOYEE_NAME:
                return `${process.env.REACT_APP_API_URL}/employee-service`;
                break;

            case LEAVE_NAME:
                return `${process.env.REACT_APP_API_URL}/leave-service`;
                break;

            default :
                return ""
        }
    }
};
