import React, {useEffect, useState} from "react";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import { getHostByAppName, LEAVE_NAME} from "../../../service/host";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Toast from "react-bootstrap/Toast";
import Row from "react-bootstrap/Row";
import {getStatus} from "./data/leave.column";

export default function LeaveDetail({hideDetails, selectedElement}) {
    const [leave, setLeave] = React.useState({});

    useEffect(
        () => {
            console.log("Hide leave details :", hideDetails);
            if (!hideDetails) {
                let idSelectedElement = "";
                if (selectedElement !== null && selectedElement !== undefined) {
                    idSelectedElement = selectedElement.id;
                    console.log("leave Id for details :", idSelectedElement);
                    service.getById(getHostByAppName(LEAVE_NAME), "leaves", setLeave, (error) => console.log(error), idSelectedElement);
                    console.log(leave)
                }
            }
        },
        [hideDetails]
    );

    return !hideDetails &&

        <div>
            {leave !== undefined && (
                <div className="p-3 my-2 rounded">

                    <Toast>
                        <ToastHeader>
                            Type de congé
                        </ToastHeader>
                        <ToastBody>
                            {leave.leaveType && leave.leaveType.code}
                            <br/>
                        </ToastBody>
                    </Toast>

                    <Toast>
                        <ToastHeader>
                            Période de congés
                        </ToastHeader>
                        <ToastBody>
                            {leave.start}  - {leave.end}
                            <br/>
                        </ToastBody>
                    </Toast>

                    <Toast>
                        <ToastHeader>
                            Acceptation client
                        </ToastHeader>
                        <ToastBody>
                            {getStatus(leave.statusClient)}
                            <br/>
                        </ToastBody>
                    </Toast>

                    <Toast>
                        <ToastHeader>
                            Acceptation RH
                        </ToastHeader>
                        <ToastBody>
                            {getStatus(leave.statusRH)}
                            <br/>
                        </ToastBody>
                    </Toast>
                </div>
            )}
        </div>;
}
