import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";
import {updateStatusRH} from "../../../components/services/crud.service";
import {getHostByAppName, LEAVE_NAME} from "../../../../service/host";


function acceptLeave(obj) {

    console.log(obj);
    let listIds = [];
    obj.selectedRows.map(elt => {
        listIds.push({"id": elt.id});
    });

    updateStatusRH(getHostByAppName(LEAVE_NAME), "leaves", window.location.reload(false), () => {
    }, listIds, 'APPROVED')
};

function refuseLeave(obj) {

    console.log(obj);
    let listIds = [];
    obj.selectedRows.map(elt => {
        listIds.push({"id": elt.id});
    });

    updateStatusRH(getHostByAppName(LEAVE_NAME), "leaves", window.location.reload(false), () => {
    }, listIds, 'REFUSED')
};

const LeaveValidateAction = [
    {
        "typeAction": "accept_leave",
        "actionHandlerInte": acceptLeave,
        "actionName": <IntlMessages id={`action.common.accept.leave`}/>,
        "patternEnable": "1,*"
    },
    {
        "typeAction": "refuse_leave",
        "actionHandlerInte": refuseLeave,
        "actionName": <IntlMessages id={`action.common.refuse.leave`}/>,
        "patternEnable": "1,*"
    }
]

export default LeaveValidateAction;
