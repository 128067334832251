import LeaveDetail from "../views/leaveDays/leave/leave.detail";
import ValidateLeave from "../views/leaveDays/leave_validate/validate.leave";
import {LeaveValidateColumn} from "../views/leaveDays/leave_validate/data/leave.validate.column";
import LeaveValidateAction from "../views/leaveDays/leave_validate/data/leave.validate.action";

const LeaveValidateRoute = [

    {resource: "leaves_validate", type: "index", host:`${process.env.REACT_APP_API_URL}/leave-service`, columns:LeaveValidateColumn, actions: LeaveValidateAction, detailComponent : LeaveDetail},
    {resource: "leaves_validate", type:"validate", host :`${process.env.REACT_APP_API_URL}/leave-service`, component: ValidateLeave}
];

export default LeaveValidateRoute;