import React, {useState, useRef, useMemo, useEffect} from "react";
import {Header, Footer, Sidebar, HorizontalSidebar} from "@adaming/ada-react-component";
//import ThemeSetting from "../components/themesetting/ThemeSetting";
import LeaveDaysRoute from "../routes/leave.days.route"
import themeActions from "../redux/themeChanger/actions.js";
import settingactions from "../redux/themeSettings/actions";
import AppLocale from "../languageProvider";
//import { Button } from "reactstrap";
import {
    drawerWidth,
    miniDrawerWidth, ProfileLockScreen, roelogo2,
    //themeSettingDrawerWidth
} from "../helpers/constants";
import {Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {IntlProvider} from "react-intl";
import {ProtectedRoute} from "../routes/protected.route";
import GlobalWrapper from "./global.style";
import AdaIndexCrud from "../views/components/adaindexresource/ada.index.crud";
import AdaEditResource from "../views/components/adaeditresource/ada.edit.resource";
import {sidebarData, sidebarDataWithoutValidate} from "../helpers/sidebar";
import axios from "axios";

import {getConfigObject} from "@adaming/ada-react-component";
const {changeTheme} = themeActions;
const {sidebarMini} = settingactions;

const DashboardLayout = props => {
    const [mini, setMini] = useState(
        props.themeSetting.sidebarMiniValue
            ? props.themeSetting.sidebarMiniValue === "on"
            : false
    );

    const [menu, setMenu] = useState();

    const [statedrawerWidth] = useState(drawerWidth);
    const [stateminiDrawerWidth, setStateminiDrawerWidth] = useState(
        miniDrawerWidth
    );
    const mainPanel = useRef(null);


    useEffect(() => {
        let appli = "leave-day";
        let res = axios.get(`${process.env.REACT_APP_API_URL}/iam-service/application_modules/hasAccess?application=${appli}&action=VALIDATE`, getConfigObject());
        res.then(res => {
            if (res.data.access) {
                setMenu(sidebarData);
            } else {
                setMenu(sidebarDataWithoutValidate);
            }
        });
    }, []);

    useMemo(() => {
        setMini(props.themeSetting.sidebarMiniValue === "on");
    }, [props.themeSetting.sidebarMiniValue]);

    let mainPanelWidth;

    const {layoutTheme, locale, themeSetting} = props;
    const currentAppLocale = AppLocale[locale];

    let routeArrowIcon = {
        backgroundColor: props.sidebarTheme.backgroundColor,
        color: props.sidebarTheme.textColor,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        position: "fixed",
        zIndex: 1,
        fontSize: "18px",
        padding: "4px 5px"
    };

    if (themeSetting.layout === "vertical") {
        mainPanelWidth = {
            width: mini
                ? `calc(100% - ${miniDrawerWidth})`
                : `calc(100% - ${drawerWidth})`,
            backgroundColor: layoutTheme.backgroundColor
        };
    } else if (themeSetting.layout === "horizontal") {
        mainPanelWidth = {
            width: '100%',
            backgroundColor: layoutTheme.backgroundColor
        };
    }

    const drawerMiniMethod = () => {
        if (mini) {
            setMini(false);
            props.sidebarMini("off");
        } else {
            setMini(true);
            props.sidebarMini("on");
        }
    };

    // const themeSettingDrawer = () => {
    //     if (themeDrawer) {
    //         setThemeDrawer(false);
    //     } else {
    //         setThemeDrawer(true);
    //     }
    // };

    const mouseEnter = () => {
        if (mini) {
            setStateminiDrawerWidth(drawerWidth);
        }
    };

    const mouseLeave = () => {
        if (mini) {
            setStateminiDrawerWidth(miniDrawerWidth);
        }
    };

    const closeDrawer = () => {
        setMini(true);
    };
    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <GlobalWrapper {...props}>
                {
                    themeSetting.layout === "vertical" &&
                    (menu && <Sidebar
                        mini={mini}
                        drawerWidth={statedrawerWidth}
                        miniDrawerWidth={stateminiDrawerWidth}
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                        closeDrawer={() => closeDrawer}

                        sidebarData={menu}
                        logoOrganization={roelogo2}
                        appName="ERP ADM"
                        {...props}
                    />)
                }
                {
                    themeSetting.layout === "horizontal" &&
                    <div className="hor_mobile_sidebar">
                        <Sidebar
                            mini={mini}
                            drawerWidth={statedrawerWidth}
                            miniDrawerWidth={stateminiDrawerWidth}
                            onMouseEnter={mouseEnter}
                            onMouseLeave={mouseLeave}
                            closeDrawer={() => closeDrawer}
                            {...props}
                        />
                    </div>
                }


                {/* <ThemeSetting
                    mini={themeDrawer}
                    drawerWidth={themeSettingDrawerWidth}
                    miniDrawerWidth={stateminiDrawerWidth}
                    closeSettingDrawer={() => themeSettingDrawer}
                    {...props}
                /> */}

                <div
                    id="main-panel"
                    className="main-panel flex-y"
                    ref={mainPanel}
                    style={mainPanelWidth}
                >
                    <div>
                        {
                            themeSetting.layout === "horizontal" &&
                            <HorizontalSidebar
                                {...props}
                                drawewrMiniMethod={drawerMiniMethod}
                            />
                        }
                        {
                            themeSetting.layout === "vertical" && props.themeSetting.toolbarDisplayValue === "show" &&
                            (
                                <Header
                                    drawerMiniMethod={drawerMiniMethod}
                                    mini={mini}
                                    drawerWidth={statedrawerWidth}
                                    miniDrawerWidth={stateminiDrawerWidth}

                                    imgProfile={ProfileLockScreen}
                                    {...props}
                                />
                            )
                        }
                    </div>

                    {/* <Button
                        className="setting-button"
                        onClick={themeSettingDrawer}
                        style={{
                            backgroundColor: props.sidebarTheme.backgroundColor,
                            color: props.sidebarTheme.textColor
                        }}
                    >
                        <i className="fas fa-cogs" />
                    </Button> */}

                    {themeSetting.toolbarDisplayValue !== "show" && (
                        <button
                            style={routeArrowIcon}
                            className="c-btn mini-drawer-menu-icon-hide-topbar"
                            onClick={drawerMiniMethod}
                        >
                            <i className="fas fa-bars"/>
                        </button>
                    )}

                    {/* Route Layout Start*/}
                    <div
                        className="route-height flex-1 overflow-auto"
                        style={
                            themeSetting.toolbarDisplayValue === "show"
                                ? {
                                    background:
                                    layoutTheme.backgroundColor
                                }
                                : {
                                    background:
                                    layoutTheme.backgroundColor
                                }
                        }
                    >
                        <Switch>
                            <ProtectedRoute {...props}>
                                {LeaveDaysRoute.map((prop, key) => {
                                    console.log("/" + prop.resource + (prop.type === "new" ? "/new" : (prop.type === "update" ? "/:id/edit" : (prop.type === "index" ? "" : "/:id/" + prop.type))));
                                    return (
                                        <Route
                                            exact
                                            path={"/" + prop.resource + (prop.type === "new" ? "/new" : (prop.type === "update" ? "/:id/edit" : (prop.type === "index" ? "" : "/:id/" + prop.type)))}
                                            component={() => prop.component ? <prop.component  {...props}/> :
                                                (prop.type === "index" ?
                                                    <AdaIndexCrud context="leaves" host={prop.host}
                                                                  detailComponent={prop.detailComponent}
                                                                  columns={prop.columns}
                                                                  actions={prop.actions} {...props}/> :
                                                    <AdaEditResource edit={prop.edit} host={prop.host}
                                                                     context={prop.resource} {...props}/>)
                                            }
                                            key={key}

                                        />
                                    );
                                })}
                            </ProtectedRoute>
                        </Switch>
                    </div>
                    {/* Route Layout Finish*/}
                    <div>
                        {themeSetting.footerDisplayValue === "show" && (
                            <Footer
                                {...props}
                                mini={mini}
                                drawerWidth={statedrawerWidth}
                                miniDrawerWidth={stateminiDrawerWidth}
                            />
                        )}
                    </div>
                </div>
            </GlobalWrapper>
        </IntlProvider>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        LanguageSwitcher: state.LanguageSwitcher,
        locale: state.LanguageSwitcher.language.locale,
        authData: {
            token: state.auth.accessToken,
            isLogin: state.auth.isLogin
        },
        themeSetting: {
            toolbarAlignValue: state.themeSetting.toolbarAlignValue,
            footerAlignValue: state.themeSetting.footerAlignValue,
            sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
            toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
            footerDisplayValue: state.themeSetting.footerDisplayValue,
            sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
            transparentImage: state.themeSetting.transparentImage,
            activeLinkStyle: state.themeSetting.activeLinkStyle,
            sidebarMiniValue: state.themeSetting.sidebarMiniValue,
            layout: state.themeSetting.layout,
            sidebarTransParentActiveBack:
            state.themeSetting.sidebarTransParentActiveBack,
            sidebarTransParentActiveColor:
            state.themeSetting.sidebarTransParentActiveColor
        }
    };
};

export default connect(
    mapStateToProps,
    {
        changeTheme,
        sidebarMini
    }
)(DashboardLayout);
