import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import {Controller, useForm} from "react-hook-form";
import IntlMessages from "../../../helpers/intlMessages";
import {EMPLOYEE_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../../service/host";
import {AdaSelect, AdaButton} from "@adaming/ada-react-component";
import {getAllForSelect} from "../../../service/crud.service";
import {getAllPersonSelect} from "../../../service/employee.service";

export default function AdvancedSearch({handlerAdvancedSearch, handlerCancelAdvancedSearch}) {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [employeeList, setEmployeeList] = useState([]);
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [object, setObject] = useState({employee: null, statusClient: null, statusRH: null, leaveType: null});
    const [statusClientList, setStatusClientList] = useState([{
        key: "ACCEPTED",
        label: "Acceptée",
        value: "ACCEPTED"
    }, {
        key: "NOTACCEPTED",
        label: "Non Acceptée",
        value: "NOTACCEPTED"
    }, {
        key: "OTHER",
        label: "Sans objet",
        value: "OTHER"
    }]);

    const [statusRHList, setStatusRHList] = useState([{
        key: "REQUESTED",
        label: "Attente",
        value: "REQUESTED"
    }, {
        key: "APPROVED",
        label: "Approuvée",
        value: "APPROVED"
    }, {
        key: "REFUSED",
        label: "Refusée",
        value: "REFUSED"
    }]);




    useEffect(() => {
        getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
    }, [])

    useEffect(() => {
        getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `leave_types`, setLeaveTypeList)
    }, [])


    const handleChangeLeaveType = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("leaveType", selectedOption);
        if (selectedOption !== null) {
            setValue("leaveType", selectedOption.key);
            setObject({...object, leaveType: selectedOption.key})
        } else {
            setValue("leaveType", null);
            setObject({...object, leaveType: null})
        }
    };


    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("employee", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            setObject({...object, employee: selectedOption.key})
        } else {
            setValue("employee", null);
            setObject({...object, employee: null})
        }
    };

    const handleChangeStatusClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("year", selectedOption);
        if (selectedOption !== null) {
            setValue("statusClient", selectedOption.key);
            setObject({...object, statusClient: selectedOption.key})
        } else {
            setValue("statusClient", null);
            setObject({...object, statusClient: null})
        }
    };

    const handleChangeStatusRH = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("month", selectedOption);
        if (selectedOption !== null) {
            setValue("statusRH", selectedOption.key);
            setObject({...object, statusRH: selectedOption.key})
        } else {
            setValue("statusRH", null);
            setObject({...object, statusRH: null})
        }
    };

    const onSubmit = (data) => {
        console.log("data search", data)
        console.log("object search", object)
        handlerAdvancedSearch(object);
    }

    const clear = () => {
        reset({"statusClient": null, "employee": null, "statusRH": null, "leaveType": null});
        setObject({})
        handlerCancelAdvancedSearch();
    }

    return (<div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="action.employee"/>
                        <Controller
                            as={<AdaSelect name={"employee"}/>}
                            name={"employee"}
                            isClearable
                            options={employeeList}
                            onChange={handleChangeEmployee}
                            control={control}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="leave.attribute.leaveType"/>
                        <Controller
                            as={<AdaSelect name={"leaveType"}/>}
                            name={"leaveType"}
                            isClearable
                            options={leaveTypeList}
                            onChange={handleChangeLeaveType}
                            control={control}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="leave.attribute.statusClient"/>
                        <Controller
                            as={<AdaSelect name={"statusClient"}/>}
                            name={"statusClient"}
                            isClearable
                            errors={errors}
                            options={statusClientList}
                            onChange={handleChangeStatusClient}
                            control={control}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="leave.attribute.statusRH"/>
                        <Controller
                            as={<AdaSelect name={"statusRH"}/>}
                            name={"statusRH"}
                            isClearable
                            errors={errors}
                            options={statusRHList}
                            onChange={handleChangeStatusRH}
                            control={control}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>


            </Row>
            <Row>
                <Col className="col-sm-9">
                </Col>
                <Col>
                    <FormGroup>
                        <AdaButton className="c-danger col-xl-5" onClick={() => clear()}>
                            <IntlMessages id="action.common.cancel"/>
                        </AdaButton>
                        <AdaButton
                            className="c-warning col-xl-6"
                            type="submit"
                            style={{marginLeft: 10}}
                        >
                            <IntlMessages id="common.search"/>
                        </AdaButton>
                    </FormGroup>
                </Col>
            </Row>
        </form>
    </div>)
}