import React from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {CustomInput, FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../helpers/intlMessages";
import {editMethod, getAllForSelect, getByIdMethod} from "../services/crud.service";
import {getEnumList} from "../enums/generic.enum";
import InputMask from "react-input-mask";

export default function AdaEditResource(props) {
    const id = props.location.pathname.split("/")[2];
    const {handleSubmit, register, errors, setValue} = useForm();
    const [resource, setResource] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState("");
    const [dataContinentSelect, setDataContinentSelect] = React.useState([]);
    const [dataLanguageSelect, setDataLanguageSelect] = React.useState([]);
    const [dataCurrencySelect, setDataCurrencySelect] = React.useState([]);
    const [dataUnitTimeSelect, setDataUnitTimeSelect] = React.useState([]);
    const [dataUnitTypeEnumSelect, setDataUnitTypeEnumSelect] = React.useState([]);
    const [dataUnitCategoryEnumSelect, setDataUnitCategoryEnumSelect] = React.useState([]);

    let history = useHistory();

    React.useEffect(() => {
        if(id !== undefined){
            getByIdMethod(props.host, props.context, setResource, setErrorMessage, id)}
    }, [id]);

    React.useEffect(() => {
        props.edit.map(data => {
            register({name: data.name}, data.validation);
            if (data.resource && data.type === 'select') {

                if (data.name === 'continent')  
                    getAllForSelect(`${process.env.REACT_APP_API_URL}/nomenclature-service`, data.resource, setDataContinentSelect);
                else if (data.name === 'languages')
                    getAllForSelect(`${process.env.REACT_APP_API_URL}/nomenclature-service`, data.resource, setDataLanguageSelect);
                else if (data.name === 'currency')
                    getAllForSelect(`${process.env.REACT_APP_API_URL}/nomenclature-service`, data.resource, setDataCurrencySelect);
                else if (data.name === 'unit')
                    getAllForSelect(`${process.env.REACT_APP_API_URL}/nomenclature-service`, data.resource, setDataUnitTimeSelect);
            } else if (data.enum && data.type === 'select') {
                if (data.enum === 'E_UT')
                    getEnumList(data.enum, setDataUnitTypeEnumSelect);
                else if (data.enum === 'E_UC')
                    getEnumList(data.enum, setDataUnitCategoryEnumSelect);
            }
        });
    }, []);

    const handleChangeContinent = selectedOption => {
        if (selectedOption !== null) {
            setValue("continent", {"id": selectedOption.key});
        } else {
            setValue("continent", undefined);
        }
    };

    const handleChangeLanguages = selectedOption => {
        if (selectedOption !== null) {
            setValue("languages", [{"id": selectedOption.key}]);
        } else {
            setValue("languages", undefined);
        }
    };

    const handleChangeCurrency = selectedOption => {
        if (selectedOption !== null) {
            setValue("currency", {"id": selectedOption.key});
        } else {
            setValue("currency", undefined);
        }
    };

    const handleChangeUnitType = selectedOption => {
        if (selectedOption !== null) {
            setValue("type", selectedOption.key);
        } else {
            setValue("type", undefined);
        }
    };

    const handleChangeUnitCategory = selectedOption => {
        if (selectedOption !== null) {
            setValue("category",selectedOption.key);
        } else {
            setValue("category", undefined);
        }
    };

    const handleChangeUnit = selectedOption => {
        if (selectedOption !== null) {
            setValue("unit", {"id": selectedOption.key});
        } else {
            setValue("unit", undefined);
        }
    };

    React.useEffect(() => {
        if (resource !== undefined) {
            props.edit.map(data => {
                if (data.type === 'select') {
                    if (data.resource && resource[data.name] !== undefined) {
                        let elmSelect = {
                            label: resource[data.name],
                            value: resource.id,
                        };
                        data.defaultValue = elmSelect;
                        setValue(resource[data.name], elmSelect.value);
                    }
                }
            })
        }
    }, [resource]);

    const onSubmit = data => {

        if (id !== undefined && id !== "new"){
            data = {...data, "id" : id};
        }

        editMethod(props.host, props.context, () => history.push("/" + props.context), setErrorMessage, data)
    };

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col>
                                        <Label>
                                            <p style={{color: "red"}}> {errorMessage}</p>
                                        </Label>
                                        {
                                            props.edit.map((data, i) =>
                                                (data.type === 'text' && <FormGroup key={data.name + i}>
                                                    <IntlMessages
                                                        id={data.label}
                                                    />
                                                    <AdaInputText
                                                        placeholder={data.placeholder}
                                                        name={data.name}
                                                        defaultValue={resource[data.name]}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>)
                                                ||
                                                (data.type === 'time' && <FormGroup key={data.name + i}>
                                                    <IntlMessages
                                                        id={data.label}
                                                    />
                                                    <AdaInputText
                                                        placeholder={data.placeholder}
                                                        name={data.name}
                                                        defaultValue={resource[data.name]}
                                                        errors={errors}
                                                        innerRef={register}
                                                        mask={data.mask}
                                                        maskChar={data.maskChar}
                                                        tag={InputMask}
                                                        onChange={e => setValue(data.name, e.target.value)}
                                                    />
                                                </FormGroup>)
                                                ||
                                                (data.type === 'number' && <FormGroup key={data.name + i}>
                                                    <IntlMessages
                                                        id={data.label}
                                                    />
                                                    <AdaInputText
                                                        placeholder={data.placeholder}
                                                        name={data.name}
                                                        defaultValue={resource[data.name]}
                                                        errors={errors}
                                                        innerRef={register}
                                                        mask={data.mask}
                                                        maskChar={data.maskChar}
                                                        tag={InputMask}
                                                        onChange={e => setValue(data.name, e.target.value)}
                                                    />
                                                </FormGroup>)||
                                                (data.type === 'date' && <FormGroup key={data.name + i}>
                                                    <IntlMessages
                                                        id={data.label}
                                                    />
                                                    <AdaInputText
                                                        placeholder={data.placeholder}
                                                        name={data.name}
                                                        defaultValue={resource[data.name]}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>)
                                                ||
                                                (data.type === 'boolean' && <FormGroup key={data.name + i}>
                                                    <IntlMessages
                                                        id={data.label}
                                                    />
                                                    <CustomInput
                                                        type="switch"
                                                        id={data.name + i}
                                                        name={data.name}
                                                        defaultChecked={data.defaultValue}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>)
                                                ||
                                                (!data.enum && data.type === 'select' && <FormGroup key={data.name + i}>
                                                    <FormGroup key={data.name + i}>
                                                        <IntlMessages
                                                            id={data.label}
                                                        />

                                                        <AdaSelect
                                                            placeholder={data.placeholder}
                                                            name={data.name}
                                                            defaultValue={data.defaultValue}
                                                            options={(data.name == 'continent')?dataContinentSelect:(data.name === 'languages' ?dataLanguageSelect:(data.name === 'currency'?dataCurrencySelect:(data.name === 'unit' ?dataUnitTimeSelect:[])))}
                                                            errors={errors}
                                                            innerRef={register}
                                                            onChange={(data.name == 'continent')?handleChangeContinent:(data.name === 'languages' ?handleChangeLanguages:(data.name === 'currency'?handleChangeCurrency:(data.name === 'unit' ?handleChangeUnit:[])))}
                                                        />



                                                    </FormGroup>
                                                </FormGroup>)
                                                ||
                                                (data.enum &&  data.type === 'select' && <FormGroup key={data.name + i}>
                                                    <FormGroup key={data.name + i}>
                                                        <IntlMessages
                                                            id={data.label}
                                                        />

                                                        <AdaSelect
                                                            placeholder={data.placeholder}
                                                            name={data.name}
                                                            defaultValue={data.defaultValue}
                                                            options={dataUnitTypeEnumSelect}
                                                            options={(data.enum == 'E_UT')?dataUnitTypeEnumSelect:(data.enum === 'E_UC' ?dataUnitCategoryEnumSelect:[])}
                                                            onChange={(data.enum == 'E_UT')?handleChangeUnitType:(data.enum === 'E_UC' ?handleChangeUnitCategory:[])}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </FormGroup>)
                                            )
                                        }
                                        <FormGroup>
                                            <AdaButton
                                                type="submit">
                                                <IntlMessages
                                                    id="iam.common.submit"
                                                />
                                            </AdaButton>
                                            <AdaButton
                                                style={{marginLeft: 10}}
                                                onClick={() => history.push(resource.id === undefined ?  './' : '../')}
                                            >
                                                <IntlMessages
                                                    id="iam.common.cancel"
                                                />
                                            </AdaButton>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}