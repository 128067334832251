import React, {useState} from "react";
import {AdaFilterSubHeader, AdaTable, getOrganizationSelected} from "@adaming/ada-react-component";
import {getAllLeavesPagination, search} from "../services/crud.service";
import AdaActions from "../adaaction/adaactions";
import IntlMessages from "../../../helpers/intlMessages";
import {getHostByAppName, LEAVE_NAME} from "../../../service/host";
import AdvancedSearch from "../search/advanced.search";

const AdaIndexResource = props => {

    const [resources, setResources] = React.useState([]);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*//*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(false);

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);

    const [dataAdvancedSearch, setDataAdvancedSearch] = useState({organization: getOrganizationSelected().id});


    React.useEffect(() => {
        getAllLeavesPagination(props.host, props.context, handlerGetAllResult,currentPaginationPerRow,currentPage)
    }, [props.refresh]);

    const handlerGetAllResult = (result) => {
        setResources(result.data);
        setFiltered(result.data);
        setMaxContent(result.headers['x-content-max'])
    };

    React.useEffect(()=>{     
        handlerAdvancedSearch(dataAdvancedSearch,currentPaginationPerRow, currentPage)
    },[currentPaginationPerRow,currentPage])

    React.useEffect(() => {
        let actionsValue = props.actions; //Actions(context, handlerOpenModalDelete);
        actionsValue.forEach(action => {
            if (action.actionHandlerInte) {
                action.actionHandler = () => {
                    setLoadingModal(true);
                    action.actionHandlerInte({"selectedRows": selectedRows})
                };
            }
            if (action.patternEnable === undefined) {
                action.disabled = false;
            } else if (action.patternEnable === "1") {
                action.disabled = (selectedRows !== undefined && selectedRows.length !== 1);
            } else if (action.patternEnable === "1,*") {
                action.disabled = (selectedRows === undefined || selectedRows.length === 0);
            }
        });
        setActionsList(actionsValue);
    }, [selectedRows.length]);


    const actionsComponent = (
        <AdaActions host={`${process.env.REACT_APP_API_URL}/leave-service`}
                    context={props.context}
                    actionsObj={actionsList}
                    selectedRows={selectedRows}
                    showActions={{exportExcel:false, add:false}}/>
    );
    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={props.columns}
            items={resources}
            setFiltredItems={setFiltered}
        />
    );
    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        if(size == null){
            size = defaultSizePerPage;
        }

        if(page == null){
            page = defaultPage;
        }
        data.organization = getOrganizationSelected().id;
        search(
            getHostByAppName(LEAVE_NAME),
            `leaves/advanced_search/get?size=${size}&page=${page}&sort=start,DESC`,
            callbackRes,
            (error) => {
                console.log(error);
            },
            data
        );
    }, []);


    const handlerCancelAdvancedSearch = React.useCallback(() => {
        console.log("handlerCancelAdvancedSearch");
        //initList(); TODO
    }, []);

    const callbackRes = res => {
        setResources(res.data);
        setFiltered(res.data);
        setMaxContent(res.headers['x-content-max'])
    };

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
        props.setSelectedRows(state.selectedRows);
    }, []);
    return (<div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (
                                            <i
                                                className="fas fa-search-minus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                    </div>
                                </legend>
                                {advancedSearchShow && (
                                    <AdvancedSearch handlerAdvancedSearch={elt => {
                                            if(elt !== dataAdvancedSearch){
                                                setDataAdvancedSearch(elt);
                                                setCurrentPage(0);
                                            }
                                            handlerAdvancedSearch(elt,currentPaginationPerRow,currentPage)
                                    }}
                                            handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}/>
                                )}
                            </fieldset>

                            <AdaTable
                                columns={props.columns}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                                paginationDefaultPage={currentPage+1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdaIndexResource;
