import React, { useState} from "react";
import {FormGroup, Row, Col} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {
    AdaButton,
    AdaInputText,
    AdaSelect,
    getCurrentEmployee,
    getConfigObject
} from "@adaming/ada-react-component";
import {post, put, getById, getAllForSelect, patch} from "../../../service/crud.service";
import {getHostByAppName, LEAVE_NAME, NOMENCLATURE_NAME} from "../../../service/host";
import {useForm} from 'react-hook-form';
import {useHistory} from "react-router-dom";
import moment from "moment";
import CustomInput from "reactstrap/es/CustomInput";
import Label from "reactstrap/es/Label";
import axios from "axios";
import ClientConfirmation from "./client.confirmation";

export default function EditLeave(props) {
    const id = props.location.pathname.split("/")[2];
    const {register, handleSubmit, errors, setValue, control} = useForm();
    const [leave, setLeave] = useState({});
    const [startD, setStartD] = useState();
    const [leaveTypes, setLeaveTypes] = React.useState();
    const [periodTimes, setPeriodTimes] = React.useState();
    const [selectedLeaveType, setSelectedLeaveType] = React.useState();
    const [totalLeave, setTotalLeaves] = React.useState();
    const [selectedStatus, setSelectedStatus] = React.useState(false);
    const [leaveItems, setLeaveItems] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [showDetails, setShowDetails] = React.useState(false);
    const [periodTimesSelected, setPeriodTimesSelected] = React.useState([]);
    const [approve, setApprove] = useState(false);

    let history = useHistory();


    React.useEffect(() => {

        getAllForSelect(`${process.env.REACT_APP_API_URL}/nomenclature-service`, 'leave_types', setLeaveTypes);
    }, []);

    React.useEffect(() => {
        getAllForSelect(`${process.env.REACT_APP_API_URL}/nomenclature-service`, 'period_times', setPeriodTimes);
    }, []);

    React.useEffect(() => {
        if (id !== undefined) {
            //setShowDetails(true);
            getById(getHostByAppName(LEAVE_NAME), "leaves", res => {
                setLeave(res);
                setLeaveItems(res.leaveItems);
                setStartD(res.start);
                setSelectedStatus(res.statusClient);
                setSelectedLeaveType({
                    key: res.leaveType.id,
                    label: res.leaveType.name,
                    value: res.leaveType.id,
                    object: res.leaveType
                });
                processPeriodTimes(res.start, res.end, res.leaveItems);
                setShowDetails(true)
            }, () => {
            }, id)
        }
    }, [id]);

    const detail = () => {
        let start = leave.start;
        let end = leave.end;
        let total = 0;
        if (leave.end === undefined || leave.start === undefined || leave.leaveType === undefined) {
            return;
        }

        let err = false;
        if (moment(new Date(start)).isAfter(moment(new Date(end)))) {
            err = true;
            setErrorMessage("La date de début est supérieure à la date fin");
        }
        if (moment(new Date(start)).isBefore(moment.now())) {
            err = true;
            setErrorMessage("La date de début est dans le passé");
        }
        if (!err) {
            processDates(start, end);
            setStartD(start);
            setShowDetails(true);
        }
    };

    const getUri = (start, end) => {
        const response = axios.get(getHostByAppName(NOMENCLATURE_NAME) + "/not_working_days/findBy/date?startDate=" + start + "&endDate=" + end, getConfigObject());
        console.log(response);
        return response.data;
    };

    const processDates = (start, end) => {
        let data = [];
        let pTimes = [];
        let items = [];
        let dayItem = moment(start);
        let defaultPeriodTime = "";
        periodTimes.map(elt => {
            if (elt.object.byDefault) {
                defaultPeriodTime = elt;
            }
        });

        do {
            let item = {};
            items.push({
                "date": dayItem.format("YYYY-MM-DD"),
                "periodTime": defaultPeriodTime,
                "dayOff": dayItem.isoWeekday() === 6 || dayItem.isoWeekday() === 7,
                "notWorkingDay": data.some(notWorking => notWorking.date === dayItem.format("YYYY-MM-DD"))
            });
            if (dayItem.isoWeekday() !== 6 && dayItem.isoWeekday() !== 7) {
                pTimes.push(defaultPeriodTime);
            } else {
                pTimes.push(undefined);
            }
            dayItem = dayItem.add(1, "day");
        } while (dayItem.isBefore(moment(end)));
        items.push({
            "date": moment(end).format("YYYY-MM-DD"),
            "periodTime": defaultPeriodTime,
            "dayOff": dayItem.isoWeekday() === 6 || dayItem.isoWeekday() === 7,
            "notWorkingDay": data.some(notWorking => notWorking.date === moment(end).format("YYYY-MM-DD"))
        });
        pTimes.push(defaultPeriodTime);
        setLeaveItems(items);
        setPeriodTimesSelected(pTimes);
        updateTotal(pTimes);
    };

    const processPeriodTimes = (start, end, leaveItems) => {
        let pTimes = [];
        let dayItem = moment(start);
        let i = 0;

        do {
            if (dayItem.isoWeekday() !== 6 && dayItem.isoWeekday() !== 7) {
                pTimes.push(leaveItems[i].periodTime);
            } else {
                pTimes.push(undefined);
            }
            dayItem = dayItem.add(1, "day");
            i++;
        } while (dayItem.isBefore(moment(end)));
        pTimes.push(leaveItems[i].periodTime);
        setPeriodTimesSelected(pTimes);
        updateTotal(pTimes);
    };

    const updateTotal = (pTimes) => {
        let total = 0;
        pTimes.forEach(item => {
                if (item !== undefined) {
                    if (item.label === "Journée entière") {
                        total = total + 1;
                    } else {
                        total = total + 0.5;
                    }
                }
            }
        )
        setTotalLeaves(total);
    }
    const handleStatus = statusOption => {
        leave.statusClient = statusOption;
        setSelectedStatus(statusOption);

    };

    const handleLeaveType = selectedOption => {
        leave.leaveType = selectedOption.object;
        setSelectedLeaveType(selectedOption);
        detail();
    };

    const handlePeriodTime = (event, i) => {
        let pTimes = periodTimesSelected;
        leaveItems[i].periodTime = event.object;
        pTimes[i] = event;
        setPeriodTimesSelected(pTimes);
        updateTotal(pTimes);
    };

    const cancel = () => {
        history.push("/leaves");
    };

    const handleDateEnd = (obj) => {
        processDates(leave.start, leave.end);
    };

    const onSubmit = () => {
        leave.statusRH = "REQUESTED";
        leave.leaveItems = leaveItems;
        leave.leaveItems.map(elt => {
            elt.periodTime = elt.periodTime.object;
        });
        leave.total = totalLeave;
        leave.employee = getCurrentEmployee();

        if(leave.id){
            put(getHostByAppName(LEAVE_NAME), `leaves/${leave.id}`, () => history.push("/leaves"), setErrorMessage, leave)
        }
        else {
            post(getHostByAppName(LEAVE_NAME), "leaves", () => history.push("/leaves"), setErrorMessage, leave)
        }

    };

    const onPreSubmit = () => {
        if(selectedStatus === 'ACCEPTED'){
            setApprove(true);
        }
        else {
            onSubmit();
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label>
                    <p style={{color: "red"}}> {errorMessage}</p>
                </Label>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">
                                <br/>

                                <Row>
                                    <Col className="col-sm-6">
                                        <FormGroup>
                                            <IntlMessages id="leave.edit.leaveType"/>
                                            <AdaSelect
                                                name="leaveType"
                                                value={selectedLeaveType}
                                                options={leaveTypes}
                                                onChange={handleLeaveType}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-sm-4">
                                        <FormGroup>
                                            <IntlMessages id="leave.edit.validationclient"/>
                                            <Row>
                                                <Col
                                                    className="col-sm-2"
                                                    style={{"margin-top": "10px"}}
                                                >
                                                    <CustomInput
                                                        id="acc" type="radio"
                                                        name="statusClient" value="ACCEPTED"
                                                        checked={selectedStatus === 'ACCEPTED'}
                                                        innerRef={register}
                                                        label={"Validé"}
                                                        onChange={() => {
                                                            handleStatus('ACCEPTED');
                                                        }}

                                                    />
                                                </Col>
                                                <Col
                                                    className="col-sm-2"
                                                    style={{"margin-top": "10px"}}
                                                >
                                                    <CustomInput
                                                        id="cac" type="radio"
                                                        name="statusClient" value="NOTACCEPTED"
                                                        checked={selectedStatus === 'NOTACCEPTED'}
                                                        innerRef={register}
                                                        label={"Non validé"}
                                                        onChange={() => {
                                                            handleStatus('NOTACCEPTED');
                                                        }}
                                                    />
                                                </Col>
                                                <Col
                                                    className="col-sm-2"
                                                    style={{"margin-top": "10px"}}
                                                >
                                                    <CustomInput
                                                        id="oth" type="radio"
                                                        name="statusClient" value="OTHER"
                                                        checked={selectedStatus === 'OTHER'}
                                                        innerRef={register}
                                                        label={"Sans objet"}
                                                        onChange={() => {
                                                            handleStatus('OTHER');
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-sm-4">
                                        <FormGroup>
                                            <IntlMessages id="leave.edit.start"/>
                                            <AdaInputText
                                                type="date"
                                                name="start"
                                                errors={errors}
                                                defaultValue={startD}
                                                innerRef={register}
                                                onChange={event => {
                                                    leave.start = event.target.value;
                                                    detail();
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-sm-4">
                                        <FormGroup>
                                            <IntlMessages id="leave.edit.end"/>
                                            <AdaInputText
                                                type="date"
                                                name="end"
                                                errors={errors}
                                                defaultValue={leave.end}
                                                innerRef={register}
                                                onChange={event => {
                                                    leave.end = event.target.value;
                                                    handleDateEnd(event);
                                                    detail();
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </div>

                    {(showDetails &&
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body col-sm-12">
                                    <div className="goHome">
                                        <Row>
                                            <Label>
                                                <p style={{color: "black"}}>Nombre total : {totalLeave} jour(s)</p>
                                            </Label>
                                        </Row>
                                        <Row/>
                                        <Row>
                                            {leaveItems !== undefined &&
                                            leaveItems.map((item, i) =>
                                                (!item.dayOff &&
                                                    <div className="col-sm-6" key={"k" + i}>
                                                        <Row>
                                                            <Col className="mb-3 col-sm-4">
                                                                <div>
                                                                    {item.date}
                                                                </div>
                                                            </Col>
                                                            <Col className="mb-3 col-sm-8">
                                                                <AdaSelect
                                                                    name={"periodTime" + i}
                                                                    value={periodTimesSelected[i]}
                                                                    options={periodTimes}
                                                                    onChange={e => handlePeriodTime(e, i)}
                                                                    innerRef={register}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                                ||
                                                (item.dayOff &&
                                                    <div className="col-sm-6" key={"kk" + i}>
                                                        <Row>

                                                            <Col className="mb-3 col-sm-4">
                                                                <div>
                                                                    {item.date}
                                                                </div>
                                                            </Col>
                                                            <Col className="mb-3 col-sm-8">
                                                                <div>
                                                                    Journée non travaillée
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            )
                                            }
                                        </Row>
                                    </div>
                                    <div className="goHome">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <AdaButton onClick={cancel}>
                                                        <IntlMessages id="leaves.button.cancel"/>
                                                    </AdaButton>
                                                    <AdaButton style={{marginLeft: 10}} onClick={onPreSubmit}>
                                                        <IntlMessages id="leaves.button.submit"/>
                                                    </AdaButton>
                                                    <ClientConfirmation show={approve}
                                                        onConfirm={() => onSubmit()}
                                                        onCancel={() => setApprove(false)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>
            </form>
        </div>
    );
}
