import React, {useState} from "react";
import {LeaveColumn} from "./data/leave.column";
import IntlMessages from "../../../helpers/intlMessages";
import {
    AdaFilterSubHeader,
    AdaTable,
    AdaActions,
    getCurrentEmployee,
    getEmployeeConnectedLight
} from "@adaming/ada-react-component";
import {withRouter} from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import * as service from "../../../service/crud.service";
import {getHostByAppName} from "../../../service/host";
import {LEAVE_NAME} from "../../../service/host";
import {search} from "../../components/services/crud.service";
import LeaveDetail from "./leave.detail";

const context = "leaves";
const IndexLeave = props => {
    const [leaves, setLeaves] = useState([]);
    /*detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);


    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.delete`}/>,
                href: "",
                actionHandler: handlerOpenModalDelete,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.edit`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            }
        ];
    };

    const handlerOpenModalDelete = () => {
        setModalShow(true);
    };

    const callbackRes = res => {
        setLeaves(res.data);
        setFiltered(res.data);
        setMaxContent(res.headers['x-content-max'])
        
    };

    const initList = () => {
        let currentEmployee = null;
        try {
            currentEmployee = getCurrentEmployee();
        } catch (e) {
            console.log("erreur lors de la récupération de l'employe", e);
        }
        if (!currentEmployee) {
            alert("Impossible de récupérer les informations de l'employé connecté");
            return;
        }
        search(
            getHostByAppName(LEAVE_NAME),
            `leaves/advanced_search/get?size=${currentPaginationPerRow}&page=${currentPage}&sort=start,DESC`,
            callbackRes,
            (error) => {
                console.log(error);
            },
            {"statusClient": null, "employee": currentEmployee.id, "statusRH": null, "leaveType": null}
        );
    }

    
    React.useEffect(()=>{     
        initList();
    },[currentPaginationPerRow,currentPage])

    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Delete Action*/
                actionsValue[0] = {...actionsValue[0], disabled: false};
                if (selectedRows.length === 1) {
                    /*Activate : Edit Action*/
                    actionsValue[1] = {
                        ...actionsValue[1],
                        href: `/${context}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show detail true*/
                    setHideDetailPanel(false);
                }
                setActionsList(actionsValue);
                console.log("actions list : ", actionsValue);
                setAdvancedSearchShow(false);
            }
        },
        [selectedRows.length]
    );

    const callBackResDelete = () => {
        setModalShow(false);
        setModalSuccessShow(true);
    }

    const handlerDeleteAction = () => {
        console.log("in Delete action", selectedRows);
        service.deletes(getHostByAppName(LEAVE_NAME), context, callBackResDelete, ((error) => {
            console.log(error)
        }), selectedRows.map(obj => obj.id))
    };


    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const actionsComponent = (
        <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                    showActions={{exportExcel: false, add: true}}/>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={LeaveColumn}
            items={leaves}
            setFiltredItems={setFiltered}
        />
    );

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={LeaveColumn}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                            <br/>
                            {selectedRows.length === 1 &&
                            <LeaveDetail selectedElement={selectedRows[0]}/>
                            }
                            <br/>
                            <SweetAlert
                                onClick={props.processAction}
                                warning
                                showCancel
                                show={modalShow}
                                confirmBtnText="Oui"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Confirmation"
                                onConfirm={() => handlerDeleteAction()}
                                onCancel={() => setModalShow(false)}
                            >
                                <IntlMessages id="action.common.confirmation"/>
                            </SweetAlert>

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default IndexLeave;
