import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getJWT = () => {
    console.log(cookies.get('jwtToken'));
    return cookies.get('jwtToken');

};


export const getConfigObject = () => {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'authorization': getJWT(),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

};