import LeaveRoute from "./leave.route";
import LeaveValidateRoute from "./leave.validate.route"
import Maintenance from "../views/pages/error/maintenance";

let LeaveDaysRoute = [];

if (Array.isArray(LeaveRoute)) {
    LeaveDaysRoute.push(...LeaveRoute);
}

if (Array.isArray(LeaveValidateRoute)) {
    LeaveDaysRoute.push(...LeaveValidateRoute);
}
LeaveDaysRoute.push({ path: "/maintenance", component: Maintenance});
export default LeaveDaysRoute;