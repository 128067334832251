import React, {useEffect, useState} from "react";
import {Card, CardText, CardTitle, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';
import IntlMessages from "../../../helpers/intlMessages";
import {useHistory} from "react-router";
import {LEAVE_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../../service/host";
import {getById, put} from "../../../service/crud.service";
import FormGroup from "react-bootstrap/FormGroup";
import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";

export default function ValidateLeave(props) {
    const id = props.location.pathname.split("/")[2];
    const {register, handleSubmit, errors, setValue, control} = useForm();
    const [activeTab, setActiveTab] = useState("1");
    const [leave, setLeave] = useState({});
    const [errorMessage, setErrorMessage] = React.useState("");

    let history = useHistory();

    React.useEffect(() => {
        console.log(id);
        if (id !== undefined) {
            getById(getHostByAppName(LEAVE_NAME), "leaves", setLeave, () => {
            }, id)
        }
    }, [id]);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const cancel = () => {
        history.push("/leaves");
    };

    const onSubmit = data => {
        leave.statusLeave = "APPROVED";
        //leave.externalAttributes = undefined;
        put(`${process.env.REACT_APP_API_URL}/leave-service`, `leaves/${id}`, () => history.push("/leaves"), setErrorMessage, leave);
    };

    const onRefused = data => {
        //let leaveToUpdate = {id: id, statusLeave: "REFUSED"};
        leave.statusLeave = "REFUSED";
        //leave.externalAttributes = undefined;
        put(`${process.env.REACT_APP_API_URL}/leave-service`, `leaves/${id}`, () => history.push("/leaves"), setErrorMessage, leave);
    };


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h4><IntlMessages id={`leave.validate.title`}/></h4>
                <br/>
                <Label>
                    <p style={{color: "red"}}> {errorMessage}</p>
                </Label>
                <Nav tabs>
                    <NavItem className="roe-card-body">
                        <NavLink className={classnames({active: activeTab === '1'})}
                                 onClick={() => {
                                     toggle('1');
                                 }}>
                            <IntlMessages id={`leave.validate.tab.summary.title`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="roe-card-body">
                        <NavLink className={classnames({active: activeTab === '2'})}
                                 onClick={() => {
                                     toggle('2');
                                 }}>
                            <IntlMessages id={`leave.validate.tab.details.title`}/>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <Card body>
                                    <CardTitle/>
                                    <CardText>
                                        <div>
                                            <Row>
                                                <Col xs="2" className="mb-3 ada-typo-color">
                                                    <IntlMessages id={'leave.attribute.createdDate'}/>
                                                </Col>
                                                <Col xs="4" className="mb-3 ada-typo-color">
                                                    <p style={{color: "#00f"}}>
                                                        {leave.createdDate}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="2" className="mb-3 ada-typo-color">
                                                    <IntlMessages id={'leave.attribute.leaveType'}/>
                                                </Col>
                                                <Col xs="4" className="mb-3 ada-typo-color">
                                                    <p style={{color: "#00f"}}>
                                                        {leave.leaveType}
                                                    </p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs="2" className="mb-3 ada-typo-color">
                                                    <IntlMessages id={'leave.attribute.start'}/>
                                                </Col>
                                                <Col xs="4" className="mb-3 ada-typo-color">
                                                    <p style={{color: "#00f"}}>
                                                        {leave.start}
                                                    </p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs="2" className="mb-3 ada-typo-color">
                                                    <IntlMessages id={'leave.attribute.end'}/>
                                                </Col>
                                                <Col xs="4" className="mb-3 ada-typo-color">
                                                    <p style={{color: "#00f"}}>
                                                        {leave.end}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardText>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Col sm="15">
                            <Card body>
                                <CardText>
                                    <div>
                                        {leave.leaveItems !== undefined &&
                                        leave.leaveItems.map((item, i) =>
                                            (!item.dayOff &&
                                                <Row>
                                                    <Col xs="3" className="mb-3 ada-typo-color">
                                                        <div>
                                                            {item.date}
                                                        </div>
                                                    </Col>
                                                    <Col xs="4" className="mb-3 ada-typo-color">
                                                        <div>
                                                            {leave.leaveItems[i].periodTime}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                            ||
                                            (item.dayOff &&
                                                <Row>


                                                    <Col xs="3" className="mb-3 ada-typo-color">
                                                        <div>
                                                            {item.date}
                                                        </div>
                                                    </Col>
                                                    <Col xs="4" className="mb-3 ada-typo-color">
                                                        <div>
                                                            Journée non travaillée
                                                        </div>
                                                    </Col>

                                                </Row>
                                            )
                                        )
                                        }
                                    </div>
                                </CardText>
                            </Card>
                        </Col>
                    </TabPane>
                </TabContent>

                <FormGroup>
                    <AdaButton onClick={cancel}>
                        Annuler
                    </AdaButton>
                    <AdaButton onClick={()  => onRefused()} style={{marginLeft: 10}}>
                        Décliner
                    </AdaButton>
                    <AdaButton type="submit" style={{marginLeft: 10}}>
                        Valider
                    </AdaButton>
                </FormGroup>
            </form>
        </div>
    );
}
